import React, { Component } from 'react';
import MenuDocuments from './menu-documents/menu-documents';
import DocumentContentArea from './content-documents/content-documents';
import './documents-policies.scss';

class DocumentsPolicies extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="documents-policies">
                <div className="disp-flex">
                    <h1>Documents & Policies</h1>
                </div>
                {/* <div className="row"> */}
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 inner-content-area"> */}
                        {/* <div className="inner-page-contents"> */}
                        <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-5">
                            <MenuDocuments  />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-7">
                            {/* <DocumentContentArea /> */}
                            {/* {this.state.selectedMenu == '1' && <RightContentArea />} */}
                        </div>
                    </div>
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}
            </div>
        );
    }
}

export default DocumentsPolicies;
