export const unitedStates = {
    states:[
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado"

    ]
}

// export default unitedStates;