import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { getAssociates, getAssociatePDF } from './business-associates.actions';
import CreateItems from '../../components/create-items/create-items';
import AddAssociates from '../business-associates/add-associates';
import AssociateSendMail from '../business-associates/associate-send-mail';


import './business-associates.scss';

// var Base64 = require('js-base64').Base64;

class BusinessAssociates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal8: false,
            toggle: true,
            modalTitle: '',
            isEditAssociate: false,
            isSendEmail: false,
            editAssociateMode : 0,
            associateDetails: {},
            associateID: '',
            numPages: null,
            pageNumber: 1,
            // selectAction: 'Actions',
            selectOptions: [
                { id: '0', name: 'Actions' },
                { id: '1', name: 'View PDF' },
                { id: '2', name: 'Edit BA' },
                { id: '3', name: 'Send Mail' }
            ]
        }
    }

    componentDidMount() {
        this.props.getAssociates({
            "action": "get_business_associate",
            "user_id": localStorage.getItem('userId'),
            "user_token": localStorage.getItem('token'),
        });
    }



    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber],
            isEditAssociate: false
        });
    }

    toggleCreateAssosiate() {
        // this.setState({
        //     toggle: !this.state.toggle
        // });
    }

    viewAssociatePDF() {
        console.log("View PDF Clicked")
        this.props.getAssociatePDF({

            "user_id": localStorage.getItem('userId'),
            "user_token": localStorage.getItem('token'),
            "action": "get_business_associate_agreement_pdf",
            "business_associate_id": this.state.associateID
        })

    }

    handleOnChange = (e, item) => {
        console.log("ITEMS:", item);
        this.setState({
            [e.target.name]: e.target.value,

        }, () => {
            if (this.state.selectAction == "0") {


            } else if (this.state.selectAction == "1") {
                this.setState({
                    associateID: item.id
                }, () => {
                    this.viewAssociatePDF();
                })



            } else if (this.state.selectAction == "2") {

                this.setState({
                    modal8: true,
                    associateDetails: item,
                    isEditAssociate: true,
                    editAssociateMode:1,
                    modalTitle: 'Business Associate'
                })
            } else if (this.state.selectAction == "3") {
                this.setState({
                    modal8: true,
                    isSendEmail: true,
                    modalTitle: 'Set the Agreement to get reviewed and signed'
                })

            }
        })

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    componentDidUpdate() {
        console.log(this.props.file);
        if (this.props.file) {
            var printWindow = window.open('', '', 'height=800,width=800');
            printWindow.document.write(window.atob(this.props.file.toString()));
            printWindow.document.close();
            printWindow.print();
        }
    }


    render() {

        return (
            <div className="associates">
                <div className="disp-flex">
                    <h1>Business Associates</h1>
                    <button type="btn " className="create-btn" onClick={this.toggle(8)}  >
                        <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>Create Associate
                    </button>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 inner-content-area">
                        <div className="inner-page-contents">
                            {
                                this.props.loading && this.props.data.length == 0 ?
                                    <div className="no-image-cont">
                                        <img src="../images/no_data_image.png" />
                                    </div> :
                                    <div>

                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th>Created</th>
                                                    <th>Last Sent</th>
                                                    <th>Signed Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </MDBTableHead>
                                            {this.props.data.map((item, i) => {

                                                return (
                                                    <MDBTableBody>
                                                        <tr>
                                                            <td>{item.company_name}</td>
                                                            <td>{item.date} </td>
                                                            <td>{item.mailsentdate}</td>
                                                            <td>{item.signdate}</td>
                                                            <td>
                                                                <select className=" custom-select" name="selectAction" value={item.selectAction} onChange={(e) => this.handleOnChange(e, item)}>

                                                                    {this.state.selectOptions.map((item, id) => {
                                                                        return (
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    })
                                                                    }

                                                                </select>
                                                            </td>
                                                        </tr>


                                                    </MDBTableBody>
                                                )
                                            })}
                                        </MDBTable>
                                    </div>
                            }
                        </div>
                    </div>

                </div>

                <MDBContainer>
                    <MDBModal isOpen={this.state.modal8} toggle={this.toggle(8)} side fullHeight position="top-right" >
                        <MDBModalHeader toggle={this.toggle(8)}>{this.state.modalTitle}</MDBModalHeader>
                        <AddAssociates isEditAssociate={this.state.isEditAssociate} editAssociateMode={this.state.editAssociateMode} associateDetails={this.state.associateDetails} closeModal={this.toggle(8)} />

                        {/* {this.state.isEditAssociate? 
                        <AddAssociates isEditAssociate={this.state.isEditAssociate} editAssociateMode={this.state.editAssociateMode} associateDetails={this.state.associateDetails} closeModal={this.toggle(8)} />
                        : 
                        this.state.isSendEmail ?
                        <AssociateSendMail/>
                        :''
                        } */}
                    </MDBModal>
                </MDBContainer>

            
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { associates } = state;
    return associates;
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAssociates, getAssociatePDF }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAssociates))
