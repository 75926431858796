import React, { Component } from 'react';
import LessonIntroduction from '../../pages/training/lesson-introduction/lesson-introduction';
import './right-content-area.scss';

class RightContentArea extends Component {

    render() {
        return (
            <div className="rightcontentarea">
                <LessonIntroduction />
                {/* <ChapterContainer/> */}
            </div>
        );
    }
}

export default RightContentArea;
