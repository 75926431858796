import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './cyber-security.scss';

export class CyberSecurity extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="security">
                <div className="row">
                    <div className="col-lg-10 col-md-11 col-sm-12 col-xs-12 cyber-content-area">
                        <div className="disp-flex">
                            <h1>Cyber Security</h1>
                            <Link to="/home/hippa-assessment"><button type="btn " className="start-assessment-btn" >
                                Start the Assessment
                        </button></Link>
                        </div>
                        <div className="inner-page-contents">
                            <img src="/images/hippa-img.png" />
                            <div className="hippa-contents">
                                <h5>HIPPA RISK ASSESSMENT</h5>
                                <h6>Your Mandatory HIPPA risk assessment details your Practice's risk level across a variety of catagories within HIPPA law.</h6>
                                <hr />
                                <p>Conducting a security risk assessment is a key requirement of the HIPAA Security Rule and a core requirement for providers seeking payment through the Medicare and Medicaid EHR Incentive Program, commonly known as the Meaningful Use Program.</p>
                                <p className="question">What is the difference between addressable and required implementation specifications in the Security Rule?</p>
                                <div className="diff-div">
                                    <span className="diff-text">Required</span>
                                    <p>
                                        "Required" rules are quite cut and dried. Either you implement them, or you automatically fail to comply with the Security Rule. These mandatory
                                        rules represent 48% of the HIPAA Security Rule. "Addressable" constitutes 52% of Security Rule specifications, and many entities do not fully
                                        understand what that entails.
                                    </p>
                                </div>
                                <div className="diff-div">
                                    <span className="diff-text">Standard</span>
                                    <p>
                                        Should trigger affirmative action to determine the most appropriate way to implement the measure.
                                    </p>
                                </div>
                                <div className="diff-div">
                                    <span className="diff-text">Addressable</span>
                                    <p>
                                    Addressable requirements are often technical, and allow organizations the flexibility to implement different security controls to accomplish the requirement's objective.<br/>
                                    For example, if I had addressable specifications to cook a turkey, I could cook it in the oven like the recipe dictates, or I could BBQ, deep-fry, smoke, or microwave it. It doesn't 
                                    matter how I cook it, just that it gets cooked (and doesn't give me foodpoisoning).
                                    </p>
                                </div>
                                <hr/>

                                <p className="question">What are my options with addressable HIPAA requirements?</p>
                                <p>The HHS explains entities have three options with addressable implementation specifications.</p>
                                {/* <ul>
                                    <li>Lesson 1: Introduction to HIPAA</li>
                                    <li>Lesson 2: Transactions, Code sets, and Identifiers</li>
                                    <li>Lesson 3: Privacy</li>
                                    <li>Lesson 4: Security</li>
                                    <li>Lesson 5: ARRA/HITECH Act And Omnibus Rule</li>
                                    <li>Lesson 6: Implementation</li>
                                </ul>
                                <p>Each course will test your knowledge along the way will skill checks and short quizzes. Once you have completed all 6 lessons you will take your final exam
                                    in order to earn your certificate of completion. Your certificate will be valid for one calendar year.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
