
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Dashboard } from '../dashboard/dashboard';
import DocumentsPolicies from '../documents-policies/documents-policies';
import BusinessAssociates from '../business-associates/business-associates';
import LeftNavPage from '../leftnav-page/leftnav-page';
import { CyberSecurity } from '../cyber-security/cyber-security';
import HippaAssessment from '../hippa-assessment/hippa-assessment';


export default class SubRoutes extends Component {

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/home/dashboard" component={Dashboard} />
                    <Route path="/home/documents-policies" component={DocumentsPolicies} />
                    <Route path="/home/business" component={BusinessAssociates} />
                    <Route path="/home/training" component={LeftNavPage} />
                    <Route path="/home/security" component={CyberSecurity} />
                    <Route path="/home/hippa-assessment" component={HippaAssessment} />
                </Switch>
            </div>
        );
    }
}

