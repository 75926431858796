import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAILURE} from '../pages/login-page/LoginConstants';
const initialState = {
    loading: false,
    data: [],
    error: null
  };
  
   function LoginReducer(state = initialState, action) {
       switch (action.type) {
      case LOGIN_REQUEST:
        return {
          ...state,
          loading: true
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          data:action.payload
        };
      case LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }

  export default LoginReducer ;