import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBFormInline, MDBInput } from 'mdbreact';
import { getHippaAssessment, saveAnswer } from './hippa-assessment.actions';
import './hippa-assessment.scss';

class HippaAssessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            radio: '',
            selectedOption: '',
            selectedId: '',
            selectedAnswerType: '',
            
        }
    }

    componentDidMount() {
        console.log("Inside Did Mount");
        this.props.getHippaAssessment(
            {
                "user_id": localStorage.getItem('userId'),
                "user_token": localStorage.getItem('token'),
                "action": "get_hippa_risk_assessment_question_answered"
            }
        );
    }

    componentDidUpdate(prevProps){
        console.log('this.props.questionAnswer: ', this.props.questionAnswer);
        if (this.props.questionAnswer !== prevProps.questionAnswer) {
            this.setState({
                data: this.props.questionAnswer
            })
        }
      }


    handleOptionChange = (e, val, item) => {
    
        this.setState({
            selectedId: item.id,
            selectedAnswerType: val
        });

        this.props.saveAnswer(
                    {
                        "user_id":localStorage.getItem('userId'),
                        "user_token":localStorage.getItem('token'),
                        "questionid":item.id,
                        "answer": val,
                        "random":this.props.random,
                        "action":"save_answer"
                    }
        )

        

        // this.setState({
        //     data: myData
        // },()=> {
        //     // this.props.saveAnswer(
        //     //     {
        //     //         // "user_id": localStorage.getItem('userId'),
        //     //         // "user_token": localStorage.getItem('token'),
        //     //         // "action": "get_hippa_risk_assessment_question_answered"

        //     //         "user_id":localStorage.getItem('userId'),
	    //     //         "user_token":localStorage.getItem('token'),
	    //     //         "questionid":qa.id,
	    //     //         "answer":qa.val,
	    //     //         "random":"",
	    //     //         "action":"save_answer"
        //     //     }
        //     // );
        // })
       
      }

    // onClick = (e, val) => {
    //     console.log('arr', this.state.radio)
    //     console.log('eis', e.target, val)
    //     let find = this.state.radio.find(data => {
    //         return data.val == val
    //     })
    //     console.log('find is', find)
    //     let arr = this.state.radio
    //     arr.push({ id: e.target.name, val })
    //     this.setState({
    //         radio: arr
    //     })

    //     //     this.setState({
    //     //       radio: nr,

    //     //     });
    //     //   };
    // }

    // onClick = (nr) => {
       
    //         this.setState({
    //           radio: nr

    //         });
    //       };

    render() {
        console.log("QUesionAnswer", this.props.questionAnswer);
        console.log("Ranodm number", this.props.random);
        return (
            <div className="hippa-assessment">
                <div className="row">
                    <div className="col-lg-10 col-md-11 col-sm-12 col-xs-12 assessment-area">
                        <div className="disp-flex">
                            <h2 className="page-title">Cyber Security</h2>
                            <span className="breadcrumd-text">> HIPPA Risk Assessment</span>
                        </div>
                        <div className="inner-page-contents">
                            <p>Your Mandatory HIPAA risk assessment details your Practice's risk level across a variety of catagories within HIPAA law.</p>
                            {/* <h3>Risk Management</h3> */}
                            {
                                this.state.data.map((qa) => {
                                    return (
                                      


                                        <ol className={`question-list ${qa.faded ? 'fadedQuestion' : ''}`}>
                                            {qa.type === 'notes' ?
                                                <li className="list-none">
                                                    <h6 className="notes">{qa.title}</h6>

                                                </li> : qa.type === 'question' ?
                                                    <li>
                                                        <h3 className="question"><span>{qa.id}.</span> <span>{qa.title}</span></h3>
                                                        <div className="disp-flex ques-options">
                                                        <label className="option-label">
                                                            <input type="radio" name={qa.id} value={qa.value} checked={qa.answer === 'Yes' || (this.state.selectedId === qa.id && this.state.selectedAnswerType === 'Yes')} onChange={(e) => this.handleOptionChange(e,'Yes', qa)}  />
                                                            Yes 
                                                        </label>
                                                        <label className="option-label">
                                                            <input type="radio" name={qa.id} value={qa.value} checked={qa.answer === 'No' || (this.state.selectedId === qa.id && this.state.selectedAnswerType === 'No')} onChange={(e) => this.handleOptionChange(e,'No', qa)}  />
                                                            No 
                                                        </label>
                                                        <label className="option-label">
                                                            <input type="radio" name={qa.id} value={qa.value} checked={qa.answer === 'Dont Know' || (this.state.selectedId === qa.id && this.state.selectedAnswerType === 'Dont Know')} onChange={(e) => this.handleOptionChange(e,'Dont', qa)} />
                                                            Do Not Know 
                                                        </label>
                                                        {/* <MDBInput
                                                                    gap
                                                                    onClick={this.onClick()}
                                                                    label='Yes'
                                                                    type='radio'
                                                                    name={qa.id}
                                                                    containerClass='mr-5'
                                                                /> */}
                                                        
                                                            {/* <MDBFormInline>
                                                                <MDBInput
                                                                    gap
                                                                    onClick={e => this.onClick(e, 'yes')}
                                                                    checked={this.state.radio.forEach(data => {
                                                                        if (data.id == qa.id && data.val == 'yes') {
                                                                            return true
                                                                        }
                                                                        else {
                                                                            return false
                                                                        }
                                                                    })}
                                                                    label='Yes'
                                                                    type='radio'
                                                                    name={qa.id}
                                                                    containerClass='mr-5'
                                                                />
                                                                <MDBInput
                                                                    gap
                                                                    onClick={e => this.onClick(e, 'No')}
                                                                    checked={this.state.radio.forEach(data => {
                                                                        if (data.id == qa.id && data.val == 'No') {
                                                                            return true
                                                                        }
                                                                        else {
                                                                            return false
                                                                        }
                                                                    })}
                                                                    label='No'
                                                                    type='radio'
                                                                    name={qa.id}
                                                                    id='radio2'
                                                                    containerClass='mr-5'
                                                                />
                                                                <MDBInput
                                                                    gap
                                                                    onClick={e => this.onClick(e, 'Dont Know')}
                                                                    checked={this.state.radio.forEach(data => {
                                                                        if (data.id == qa.id && data.val == 'Dont Know') {
                                                                            return true
                                                                        }
                                                                        else {
                                                                            return false
                                                                        }
                                                                    })}
                                                                    label='Dont Know'
                                                                    type='radio'
                                                                    name={qa.id}
                                                                    id='radio3'
                                                                    containerClass='mr-5'
                                                                />
                                                            </MDBFormInline> */}
                                                        </div>
                                                    </li> : qa.type === 'heading' ?
                                                        <li>
                                                            <h2 className="ques-heading">{qa.title}</h2>
                                                        </li> : null

                                            }

                                        </ol>
                                        )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HippaAssessment.propTypes = {
    getHippaAssessment: PropTypes.func,
};


function mapStateToProps(state) {
    const { hippaAssessment } = state;
    return hippaAssessment;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getHippaAssessment, saveAnswer }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HippaAssessment))

