import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import './header.scss';

class Header extends Component {
  

    componentWillMount() {
        this.setState({ 
            firstName: localStorage.getItem('first'),
            lastName: localStorage.getItem('last'),
            userType: localStorage.getItem('type'),
        });
    }

    logout = () => {
        localStorage.clear();
    }

   

    render() {
        console.log("Header Component", this.props.location.pathname);
        return (
            <header className="header fixed-top">
                <div className="row menu-contnr">
                <div className="nav-list">
                    <ul className="disp-flex">
                            <Link to="/home/dashboard"><li className={this.props.location.pathname == '/home/dashboard' ? 'header-active' : ''}> Dashboard</li></Link>
                            <Link to="/home/documents-policies"><li className={this.props.location.pathname == '/home/documents-policies' ? 'header-active' : ''}> Documents & Policies</li></Link>
                            <Link to="/home/training"><li className={this.props.location.pathname == '/home/training' ? 'header-active' : ''}> Training </li></Link>
                            <Link to="/home/business"><li className={this.props.location.pathname == '/home/business' ? 'header-active' : ''}> Business Associate </li></Link>
                            <Link to="/home/security"><li className={this.props.location.pathname == '/home/security' ? 'header-active' : ''}> Cyber Security </li></Link>
                    </ul>
                </div>
                <div className="profile-right">
                        <div className="user-profile">
                            <div className="vl"></div>
                            <div className="icon-profile" onClick={() => this.logout()}>
                                <img src="/images/admin-profile.jpg" />
                            </div>
                            <div className="pad-left">
                                <h6 className="username">{this.state.firstName} {this.state.lastName}</h6>
                                <p>{this.state.userType}</p>
                            </div>
                        </div>
                </div>
                {/* <div className="menu-wrap disp-flex align-items-center">
                    <nav className="topnav site-navigation text-left mr-auto">
                        <ul>
                            <Link to="/home/dashboard"><li className="active" onClick={this.handleNavLink}> Dashboard</li></Link>
                            <Link to="/home/documents-policies"><li> Documents & Policies</li></Link>
                            <Link to="/home/training"><li> Training </li></Link>
                            <Link to="/home/business"><li> Business Associate </li></Link>
                            <Link to="/home/security"><li> Cyber Security </li></Link>
                        </ul>
                    </nav>
                    <div className="ml-auto profile-right">
                        <div className="user-profile">
                            <div className="vl"></div>
                            <div className="icon-profile" onClick={() => this.logout()}>
                                <img src="images/admin-profile.jpg" />
                            </div>
                            <div className="pad-left">
                                <h6 className="username">{this.state.firstName} {this.state.lastName}</h6>
                                <p>{this.state.userType}</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </header>
        );
    }
}

export default (withRouter(Header));

