import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './app/route/private-route';
import PublicRoute from './app/route/public-route';

import Login from './app/pages/login-page/login-page';
import Home from './app/pages/home/home';

export class Routes extends Component {

    render() {
        return (
            <div>
                <Switch>
                    <Redirect exact from="/" to="login" />
                    <PublicRoute path="/login" component={Login} />
                    <PrivateRoute path="/home" component={Home} />
                </Switch>
            </div>
        );
    }
}
