import React, { Component } from 'react';
import './left-nav-items.scss';

class LeftNavItems extends Component {
  constructor() {
    super();
    this.state = {
      leftMenuItems: [
        { id: 1, value: "Get Started", isActive: false },
        { id: 2, value: "Introduction to HIPAA", isActive: false },
        { id: 3, value: "Transactions, Code sets and Identifiers", isActive: false },
        { id: 4, value: "Privacy", isActive: false },
        { id: 5, value: "Security", isActive: false },
        { id: 6, value: "ARRA/HITECH Act and Omnibus Rule", isActive: false },
        { id: 7, value: "Implementation", isActive: false }
      ],
      active: false,
      selected: ''
    }
  }

  selectedMenu = (id) => {
    this.props.selectedMenu(id)
    let data = this.state.leftMenuItems;
    data.forEach(item => {
      if (item.id == id) {
        item.isActive = !item.isActive;
      } else {
        item.isActive = false;
      }
    });
    this.setState({
      leftMenuItems: data
    })
  }

  render() {
    return (
      <div className="leftnavitems">
        <ul>
          {
            this.state.leftMenuItems.map((menu, index) =>
              (
                <a href="#" onClick={() => this.selectedMenu(menu.id)}>
                  <li className={menu.isActive == true ? 'active-class' : ''} key={menu.id}>
                    <div className={menu.isActive == true ? 'text-icon-active' : 'text-icon'}>
                      {menu.value.substring(0, 2)}
                    </div>
                    <div className="pad-left">
                      {menu.value}
                    </div>
                  </li>
                </a>
              ))
          }
        </ul>

        <div className="final-exam">
          <button>Final Exam</button>
        </div>
      </div>
    );
  }
}

export default LeftNavItems;
