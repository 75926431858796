import { HttpClient } from './http.service';
import { APIPath } from '../config';

const BASE_URL = `${APIPath}`;

const headersConfig = () => ({
  // 'X-ACCESS-TOKEN': localStorage.getItem('token'),
  'content-type': 'application/json'
});

const callAPIPost = (data) => {
  return HttpClient.post(`${BASE_URL}`, data, { headers: headersConfig() });
};

export const ApiService = { callAPIPost };
