import { ApiService } from '../../services/api.service';

import { 
  GET_BUSINESS_ASSOCIATES_REQUEST, 
  GET_BUSINESS_ASSOCIATES_REQUEST_SUCCESS, 
  GET_BUSINESS_ASSOCIATES_REQUEST_FAILURE, 
  VIEW_BA_PDF_REQUEST,
  VIEW_BA_PDF_REQUEST_SUCCESS,
  VIEW_BA_PDF_REQUEST_REQUEST_FAILURE,
  ADD_ASSOCIATE_REQUEST,
  ADD_ASSOCIATE_REQUEST_SUCCESS,
  ADD_ASSOCIATE_REQUEST_FAILURE,
  EDIT_ASSOCIATE_REQUEST,
  EDIT_ASSOCIATE_REQUEST_SUCCESS,
  EDIT_ASSOCIATE_REQUEST_FAILURE,
  UPDATE_ASSOCIATE_REQUEST,
  UPDATE_ASSOCIATE_REQUEST_SUCCESS,
  UPDATE_ASSOCIATE_REQUEST_FAILURE
} from './business-associates.constants';

export function getAssociates(data) {
  return dispatch => {
    dispatch(getAssociatesRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(getAssociatesSuccess(res.data));
    })
    .catch(err => {
      dispatch(getAssociatesFailure(err.message));
    });
  };
};

export function getAssociatePDF(data){
  return dispatch => {
    dispatch(getAssociatePDFRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(getAssociatePDFSuccess(res.data));
    })
    .catch(err => {
      dispatch(getAssociatePDFFailure(err.message));
    });
  };
};

export function addAssociates(data){
  return dispatch => {
    dispatch(addAssociatesRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(addAssociatesSuccess(res.data));
    })
    .catch(err => {
      dispatch(addAssociatesFailure(err.message));
    });
  }
}

export function editAssociates(data){
  return dispatch => {
    dispatch(editAssociatesRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(editAssociatesSuccess(res.data));
    })
    .catch(err => {
      dispatch(editAssociatesFailure(err.message));
    });
  }
}

export function updateAssociates(data){
  return dispatch => {
    dispatch(updateAssociatesRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(updateAssociatesSuccess(data));
    })
    .catch(err => {
      dispatch(updateAssociatesFailure(err.message));
    });
  }
}


const getAssociatesRequested = () => ({
  type: GET_BUSINESS_ASSOCIATES_REQUEST
});

const getAssociatesSuccess = data => ({
  type: GET_BUSINESS_ASSOCIATES_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const getAssociatesFailure = error => ({
  type: GET_BUSINESS_ASSOCIATES_REQUEST_FAILURE,
  payload: {
    error
  }
});


const getAssociatePDFRequested = () => ({
  type: VIEW_BA_PDF_REQUEST
});

const getAssociatePDFSuccess = data => ({
  type: VIEW_BA_PDF_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const getAssociatePDFFailure = error => ({
  type: VIEW_BA_PDF_REQUEST_REQUEST_FAILURE,
  payload: {
    error
  }
});




const addAssociatesRequested = () => ({
  type: ADD_ASSOCIATE_REQUEST
});

const addAssociatesSuccess = data => ({
  type: ADD_ASSOCIATE_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const addAssociatesFailure = error => ({
  type: ADD_ASSOCIATE_REQUEST_FAILURE,
  payload: {
    error
  }
});

const editAssociatesRequested = () => ({
  type: EDIT_ASSOCIATE_REQUEST
});

const editAssociatesSuccess = data => ({
  type: EDIT_ASSOCIATE_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const editAssociatesFailure = error => ({
  type: EDIT_ASSOCIATE_REQUEST_FAILURE,
  payload: {
    error
  }
});



const updateAssociatesRequested = () => ({
  type: UPDATE_ASSOCIATE_REQUEST
});

const updateAssociatesSuccess = data => 
({
  type: UPDATE_ASSOCIATE_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const updateAssociatesFailure = error => ({
  type: UPDATE_ASSOCIATE_REQUEST_FAILURE,
  payload: {
    error
  }
});


