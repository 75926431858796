import React, { Component } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import './content-documents.scss';

class DocumentContentArea extends Component {

    render() {
        return (
            <div className="doccontentarea">
               <MDBTable className="doc-table">
               
                    <MDBTableBody>
                        <tr>
                        <td width="70%">Mark</td>
                        <td >
                            <span className="button-sec">
                                <button className="view-btn">View</button>
                                <button className="download-btn">Download</button>
                            </span>
                        </td>
                        </tr>
                        <tr>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        </tr>
                        <tr>
                        <td>Larry</td>
                        <td>the Bird</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>

                <ul className="document-list">
                    <li>
                        <span>(This will take few minutes to generate, as it is your full HIPAA Compliance Plan.)</span>
                        <span className="button-sec">
                                <button className="view-btn">View</button>
                                <button className="download-btn">Download</button>
                        </span>
                    </li>
                </ul>
            </div>
        );
    }
}

export default DocumentContentArea;
