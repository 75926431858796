import { ApiService } from '../../services/api.service';

import { 
  GET_HIPPA_ASSESSMENT_REQUEST, 
  GET_HIPPA_ASSESSMENT_REQUEST_SUCCESS, 
  GET_HIPPA_ASSESSMENT_REQUEST_FAILURE, 
  SAVE_ANSWER_REQUEST,
  SAVE_ANSWER_REQUEST_SUCCESS,
  SAVE_ANSWER_REQUEST_FAILURE
} from './hippa-assessment.constants';

export function getHippaAssessment(data) {
  return dispatch => {
    dispatch(getHippaAssessmentRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(getHippaAssessmentSuccess(res.data));
    })
    .catch(err => {
      dispatch(getHippaAssessmentFailure(err.message));
    });
  };
};

export function saveAnswer(data) {
  return dispatch => {
    dispatch(saveAnswerRequested());
    ApiService.callAPIPost(data).then(res => {
      let dataModified = res.data;
      dataModified.id = data.questionid;
      dataModified.answer = data.answer;
      dispatch(saveAnswerSuccess(dataModified));
    })
    .catch(err => {
      dispatch(saveAnswerFailure(err.message));
    });
  };
};


const getHippaAssessmentRequested = () => ({
  type: GET_HIPPA_ASSESSMENT_REQUEST
});

const getHippaAssessmentSuccess = data => ({
  type: GET_HIPPA_ASSESSMENT_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const getHippaAssessmentFailure = error => ({
  type: GET_HIPPA_ASSESSMENT_REQUEST_FAILURE,
  payload: {
    error
  }
});


const saveAnswerRequested = () => ({
  type: SAVE_ANSWER_REQUEST
});

const saveAnswerSuccess = data => ({
  type: SAVE_ANSWER_REQUEST_SUCCESS,
  payload: {
    ...data
  }
});

const saveAnswerFailure = error => ({
  type: SAVE_ANSWER_REQUEST_FAILURE,
  payload: {
    error
  }
});



