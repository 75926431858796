
    export const GET_BUSINESS_ASSOCIATES_REQUEST =  'GET_BUSINESS_ASSOCIATES_REQUEST';
    export const GET_BUSINESS_ASSOCIATES_REQUEST_SUCCESS = 'GET_BUSINESS_ASSOCIATES_REQUEST_SUCCESS';
    export const GET_BUSINESS_ASSOCIATES_REQUEST_FAILURE =  'GET_BUSINESS_ASSOCIATES_REQUEST_FAILURE';

    export const VIEW_BA_PDF_REQUEST =  'VIEW_BA_PDF_REQUEST';
    export const VIEW_BA_PDF_REQUEST_SUCCESS = 'VIEW_BA_PDF_REQUEST_SUCCESS';
    export const VIEW_BA_PDF_REQUEST_REQUEST_FAILURE =  'VIEW_BA_PDF_REQUEST_REQUEST_FAILURE';

    export const ADD_ASSOCIATE_REQUEST = 'ADD_ASSOCIATE_REQUEST';
    export const ADD_ASSOCIATE_REQUEST_SUCCESS =  'ADD_ASSOCIATE_REQUEST_SUCCESS';
    export const ADD_ASSOCIATE_REQUEST_FAILURE = 'ADD_ASSOCIATE_REQUEST_FAILURE';

    export const EDIT_ASSOCIATE_REQUEST = 'EDIT_ASSOCIATE_REQUEST';
    export const EDIT_ASSOCIATE_REQUEST_SUCCESS =  'EDIT_ASSOCIATE_REQUEST_SUCCESS';
    export const EDIT_ASSOCIATE_REQUEST_FAILURE = 'EDIT_ASSOCIATE_REQUEST_FAILURE';

    export const UPDATE_ASSOCIATE_REQUEST = 'UPDATE_ASSOCIATE_REQUEST';
    export const UPDATE_ASSOCIATE_REQUEST_SUCCESS =  'UPDATE_ASSOCIATE_REQUEST_SUCCESS';
    export const UPDATE_ASSOCIATE_REQUEST_FAILURE = 'UPDATE_ASSOCIATE_REQUEST_FAILURE';

    
    