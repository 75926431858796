import React, { Component } from 'react';
import Header from '../../components/header/header';
import SideNav from '../../components/sidenav/sidenav';

import './home.scss';

import SubRoutes from './subroot';

class Home extends Component {
    
    constructor() {
        super();
    }

    render() {
        return (
            <div className="cytekHome">
                <SideNav />
                <div id="main">
                    <Header />
                    <div className="main-content-area">
                        <SubRoutes />
                    </div>
                </div>

            </div>
        );
    }
}

export default Home;