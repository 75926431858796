import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { Link, withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
import {  MDBBtn,MDBInput  } from 'mdbreact';
// import { addAssociates, editAssociates, updateAssociates } from '../business-associates/business-associates.actions';
// import Input from '../../components/input/input';
// import {unitedStates} from '../../config/unitedStates';
import './associate-send-mail.scss';

class AssociateSendMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipients:[]
        }
    }

    add = () => {
        console.log("ADd Inputttttt Field")
        const recipients = this.state.recipients.concat(<MDBInput type="text"  label="Send To" name="companyName" outline />);
        this.setState({ recipients });
    }

    render(){
        return(
            <div className="send-mail">
                 <div className="mdl-body">
                    <div>
                        
                        
                    </div>
                    <a onClick={ this.add }><p>Add Another Recipient Email </p></a>
                    <div>
                        <MDBInput type="textarea" label="Message" rows="10" outline /> 
                    </div>
                 </div>
            </div>
        );;
    }

}

export default AssociateSendMail;
