import React, { Component } from 'react';
import Input from '../../components/input/input';

import './login-page.scss';

import { connect } from "react-redux";
import { login } from './loginActions';

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.loginData && nextProps.loginData.data.status == "success") {
            this.props.history.push('/home');
            localStorage.setItem('token', nextProps.loginData.data.user_token);
            localStorage.setItem('userId', nextProps.loginData.data.user_id);
            localStorage.setItem('type', nextProps.loginData.data.user_type);
            localStorage.setItem('first', nextProps.loginData.data.user_info.firstname);
            localStorage.setItem('last', nextProps.loginData.data.user_info.lastname);
            localStorage.setItem('email', nextProps.loginData.data.user_info.email);
        }
    }


    handleOnChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleLogin = () => {
        try {
            let payLoad = {
                "action": "admin_login",
                "email": this.state.email,
                "password": this.state.password
            };
            this.props.onTodoClick(payLoad);
        }
        catch (err) {
            console.log('Error is:' + err)
        }
    }

    render() {
        return (
            <div className="login container-fluid">
                <div className="login-container">
                    <div className="wrap-login">

                        <form>
                            <div className="logo">
                                <img className="img-responsive" src="images/cytek-logo.svg" />
                            </div>
                            <Input type="email" value={this.state.email} label="Email" name="email" onChange={this.handleOnChange} outline />
                            <Input type="password" value={this.state.password} label="Password" name="password" onChange={this.handleOnChange} outline />
                            <span>Forgot your password?</span>
                            <div className="disp-flex">
                                <button type="button" className="btn login-btn" onClick={this.handleLogin} >Login</button>
                            </div>
                        </form>
                    </div>
                    <div className="login-image"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loginData: state.loginData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTodoClick: data => {
            dispatch(login(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);


