import React, { Component } from 'react';
import './lesson-introduction.scss';

class LessonIntroduction extends Component {
    render() {
        return (
            <div className="lesson-introduction">
                <img src="/images/training-img.png" />
                <div className="lesson-contents">
                    <h5>HIPAA Awareness Training For Healthcare Providers</h5>
                    <p className="intro-text">Welcome to Cytek Safeguard™ HIPAA Training. This course is comprised of 6 lessons covering all the components of HIPAA Compliance.</p>
                    <ul>
                        <li>Lesson 1: Introduction to HIPAA</li>
                        <li>Lesson 2: Transactions, Code sets, and Identifiers</li>
                        <li>Lesson 3: Privacy</li>
                        <li>Lesson 4: Security</li>
                        <li>Lesson 5: ARRA/HITECH Act And Omnibus Rule</li>
                        <li>Lesson 6: Implementation</li>
                    </ul>
                    <p>Each course will test your knowledge along the way will skill checks and short quizzes. Once you have completed all 6 lessons you will take your final exam
                    in order to earn your certificate of completion. Your certificate will be valid for one calendar year.</p>
                </div>
                <hr />
                <div className="bottom-section">
                    <button>Start Training</button>
                </div>
            </div>
        );
    }
}

export default LessonIntroduction;
