import React, { Component } from 'react';
import './sidenav.scss';

class SideNav extends Component {

    constructor() {
        super();
        this.state = {
            isSideNavOpen: false

        }
    }

    openSideBar = () => {
        this.setState({
            isSideNavOpen: true
        });
    }

    closeSideBar = () => {
        this.setState({
            isSideNavOpen: false
        });
    }

    render() {

        return (
            <div className={`sidenav ${this.state.isSideNavOpen ? 'active-sidenav' : ''}`} onMouseOver={this.openSideBar} onMouseOut={this.closeSideBar}>
                <ul>
                    <li>
                        <a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 22" >
                                <defs></defs>
                                <g transform="translate(-173 -38)">
                                    <rect className="a" width="32" height="4" transform="translate(173 38)" />
                                    <rect className="a" width="32" height="4" transform="translate(173 56)" />
                                    <rect className="a" width="24" height="4" transform="translate(173 47)" />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a>
                            <span><svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 43 25.409">
                                <defs></defs>
                                <g transform="translate(-1 -6)">
                                    <g transform="translate(10.773 6)">
                                        <path className="a" d="M11.864,11.864A5.759,5.759,0,0,1,17.727,6a5.759,5.759,0,0,1,5.864,5.864,5.759,5.759,0,0,1-5.864,5.864A5.759,5.759,0,0,1,11.864,11.864Zm5.864,9.773C8.736,21.636,6,28.086,6,28.086v3.323H29.455V28.086S26.718,21.636,17.727,21.636Z" transform="translate(-6 -6)" />
                                    </g>
                                    <g transform="translate(29.732 6)">
                                        <g transform="translate(0.268)">
                                            <circle className="a" cx="5" cy="5" r="5" />
                                        </g>
                                        <g transform="translate(0 12.5)">
                                            <path className="a" d="M21.173,13A9.1,9.1,0,0,0,15.7,14.564c4.5,2.15,6.255,5.864,6.255,6.255v.2h8.014V18.473A9.094,9.094,0,0,0,21.173,13Z" transform="translate(-15.7 -13)" />
                                        </g>
                                    </g>
                                    <g transform="translate(29.732 6)">
                                        <g transform="translate(0.268)">
                                            <circle className="a" cx="5" cy="5" r="5" />
                                        </g>
                                        <g transform="translate(0 12.5)">
                                            <path className="a" d="M21.173,13A9.1,9.1,0,0,0,15.7,14.564c4.5,2.15,6.255,5.864,6.255,6.255v.2h8.014V18.473A9.094,9.094,0,0,0,21.173,13Z" transform="translate(-15.7 -13)" />
                                        </g>
                                    </g>
                                    <g transform="translate(1 6)">
                                        <g transform="translate(4)"><circle className="a" cx="5" cy="5" r="5" /></g>
                                        <g transform="translate(0 12.5)">
                                            <path className="a" d="M9.8,13a9.1,9.1,0,0,1,5.473,1.564c-4.5,2.15-6.255,5.864-6.255,6.255v.2H1V18.473A9.094,9.094,0,0,1,9.8,13Z" transform="translate(-1 -13)" />
                                        </g>
                                    </g>
                                </g></svg></span>
                            <span className="svg-icon-text">Users</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 32 29.125">
                                <defs></defs>
                                <g transform="translate(0 -24.9)">
                                    <path className="a" d="M31.093,45.762,20.585,27.55a5.3,5.3,0,0,0-9.181,0L.71,46.074A5.3,5.3,0,0,0,5.3,54.024H26.7a5.3,5.3,0,0,0,4.394-8.263Zm-12.83,1.773a2.264,2.264,0,0,1-4.527,0v-.052a2.264,2.264,0,0,1,4.527,0Zm.052-14.909L17.2,42.407a1.2,1.2,0,0,1-2.39,0l-1.12-9.787a1.079,1.079,0,0,1,1.039-1.328H17.27A1.09,1.09,0,0,1,18.315,32.626Z" transform="translate(0)" />
                                </g>
                            </svg><span className="svg-icon-text">Report Incident</span></a>
                    </li>
                    <li>
                        <a>
                            <svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 34.323 30.26">
                                <defs></defs>
                                <g transform="translate(0 -18.288)"><g transform="translate(0 18.288)">
                                    <path className="a" d="M33.459,25.414l-15.692-7a1.46,1.46,0,0,0-1.2,0l-15.691,7a1.469,1.469,0,0,0-.093,2.638L16.478,36.42a1.472,1.472,0,0,0,1.384,0l15.692-8.368a1.468,1.468,0,0,0-.095-2.638Z" transform="translate(-0.008 -18.288)" />
                                    <path className="a" d="M32.161,137.414l-15,8-15-8a1.469,1.469,0,0,0-1.383,2.593l15.691,8.371a1.472,1.472,0,0,0,1.384,0l15.692-8.371a1.47,1.47,0,0,0-1.384-2.593Z" transform="translate(0 -124.027)" />
                                    <path className="a" d="M32.161,189.084l-15,8-15-8a1.469,1.469,0,1,0-1.383,2.592l15.691,8.368a1.467,1.467,0,0,0,1.384,0l15.692-8.368a1.469,1.469,0,1,0-1.384-2.592Z" transform="translate(0 -169.957)" />
                                </g>
                                </g>
                            </svg><span className="svg-icon-text">Employee Log</span></a>
                    </li>
                    <li>
                        <a>
                            <svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 34.254 25.669">
                                <defs></defs>
                                <g transform="translate(0 -67.229)">
                                    <g transform="translate(0 67.229)">
                                        <path className="a" d="M9.243,79.763a8.577,8.577,0,0,1,3.591-.866H28V75.98A4.109,4.109,0,0,0,23.918,71.9H14v-.583a3.929,3.929,0,0,0-1.2-2.88,3.93,3.93,0,0,0-2.88-1.2H4.084a3.93,3.93,0,0,0-2.88,1.2A3.929,3.929,0,0,0,0,71.313v17.5q0,.073.009.228c.006.1.009.179.009.228l.091-.109,6.144-7.22A8.5,8.5,0,0,1,9.243,79.763Z" transform="translate(0 -67.229)" />
                                        <path className="a" d="M53.283,286.732a2.468,2.468,0,0,0-1.1-.238H32.346a6.326,6.326,0,0,0-2.616.647,6.181,6.181,0,0,0-2.2,1.577l-6.125,7.219a1.876,1.876,0,0,0-.565,1.2.81.81,0,0,0,.483.784,2.469,2.469,0,0,0,1.1.237H42.264a6.33,6.33,0,0,0,2.616-.647,6.177,6.177,0,0,0,2.2-1.577l6.125-7.219a1.876,1.876,0,0,0,.565-1.2A.809.809,0,0,0,53.283,286.732Z" transform="translate(-19.512 -272.493)" />
                                    </g>
                                </g>
                            </svg><span className="svg-icon-text">Resources</span></a>
                    </li>
                    <li>
                        <a>
                            <svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 43 35.789">
                                <defs></defs>
                                <g transform="translate(-4.016 -6.001)">
                                    <path className="a" d="M32.6,51.686,45.08,39.2,30.236,40.346Z" transform="translate(-5.62 -9.896)" />
                                    <path className="a" d="M21.222,37.619h0a5.63,5.63,0,0,1-5.666-5.614l.012-.158L5.076,29.662,23.511,48.1,21.326,37.61ZM16.7,39.762l-.943-.943.993-.993.943.943Zm.993.993.993-.993.993.993-.993.993Zm2.978,2.978-.993-.993.993-.993.993.993Z" transform="translate(-0.316 -7.052)" />
                                    <path className="a" d="M16.5,9.618,4.016,22.1l11.341,2.363Zm-2.4,12.958-1.4-.107.107-1.4,1.4.107Zm.216-2.8-1.4-.107.107-1.4,1.4.107Zm.215-2.8-1.4-.107.107-1.4,1.4.107Z" transform="translate(0 -1.078)" /><path className="a" d="M27.305,21.091l2.507-2.006a3.673,3.673,0,0,1,1.072-2.431l5.647-5.647a9.4,9.4,0,0,0-6.4,2.768l-1.3,1.3A6.207,6.207,0,0,0,27,19.5a4.944,4.944,0,0,0,.305,1.594Z" transform="translate(-5.262 -1.492)" />
                                    <path className="a" d="M30.387,17.12a2.264,2.264,0,0,0-.666,1.61l.021.435-.284.284-4.23,3.384-.364-.847a7.65,7.65,0,0,1-.759-3.014,7.6,7.6,0,0,1,2.241-5.41l.628-.627-3.69-3.69L22,25.973a4.208,4.208,0,0,0,4.214,4.158l16.676-1.285L30.774,16.734Zm8.405,9.225.108,1.4-1.4.108-.108-1.4Zm-2.7,1.616-9.827.756-.108-1.4,9.827-.756Z" transform="translate(-3.378 -0.967)" />
                                    <path className="a" d="M53.78,7.234a4.21,4.21,0,0,0-7.049,1.9,4.155,4.155,0,0,0,.147,2.6l.165.426-6.128,6.128L42.729,20.1l6.128-6.128.426.165a4.164,4.164,0,0,0,2.6.147,4.21,4.21,0,0,0,1.9-7.05ZM50.8,12.318a2.106,2.106,0,1,1,2.106-2.106A2.108,2.108,0,0,1,50.8,12.318Z" transform="translate(-7.997)" />
                                </g>
                            </svg><span className="svg-icon-text">Phishing</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 30.035 33">
                                <defs></defs>
                                <g transform="translate(-23)"><path className="a" d="M23.967,0A.967.967,0,0,0,23,.967V5.4L28.4,0Z" />
                                    <path className="a" d="M62.721,39.68V36.213L59.254,39.68Z" transform="translate(-33.917 -33.879)" />
                                    <path className="a" d="M37.757,15.361l6.671-2.368a2.893,2.893,0,0,1,1.776-.052V.967A.967.967,0,0,0,45.236,0h-14.5V6.768a.967.967,0,0,1-.967.967H23v20.69a.967.967,0,0,0,.967.967h13.1a10.5,10.5,0,0,1-1.24-4.953V18.094A2.906,2.906,0,0,1,37.757,15.361ZM33.637,3.867h7.734a.967.967,0,0,1,0,1.934H33.637a.967.967,0,0,1,0-1.934Zm-7.8,15.855h2.578a.967.967,0,1,1,0,1.934H25.836a.967.967,0,1,1,0-1.934Zm7.09,5.8h-7.09a.967.967,0,1,1,0-1.934h7.09a.967.967,0,0,1,0,1.934Zm.712-15.855a.967.967,0,0,1,0-1.934h4.576a.967.967,0,0,1,0,1.934Z" transform="translate(0)" />
                                    <path className="a" d="M266.635,231.419l-6.671-2.368a.967.967,0,0,0-.647,0l-6.671,2.368a.967.967,0,0,0-.643.911v6.344a8.646,8.646,0,0,0,7.513,8.554.965.965,0,0,0,.249,0,8.646,8.646,0,0,0,7.513-8.554V232.33A.967.967,0,0,0,266.635,231.419Zm-3.73,6.22-2.707,2.707a.967.967,0,0,1-1.367,0l-1.45-1.45a.967.967,0,1,1,1.367-1.367l.767.767,2.023-2.023a.967.967,0,1,1,1.367,1.367Z" transform="translate(-214.243 -214.236)" />
                                </g>
                            </svg><span className="svg-icon-text">Email Encryption</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <svg className="svg-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 29.939 28.18">
                                <defs></defs>
                                <g transform="translate(-8 -9.41)"><g transform="translate(8 9.41)">
                                    <path className="a" d="M49.12,40.787h0l.258.186V26.587L38.4,33.064Z" transform="translate(-19.44 -15.873)" />
                                    <path className="a" d="M10.838,17.47H10.57A2.574,2.574,0,0,0,8,20.04v1.665l2.838,1.672V17.47Z" transform="translate(-8 -12.443)" />
                                    <path className="a" d="M51.718,17.47H51.45v5.907l2.838-1.672V20.04A2.574,2.574,0,0,0,51.718,17.47Z" transform="translate(-24.349 -12.443)" />
                                    <path className="a" d="M36.95,45.16,24.063,35.874a1.869,1.869,0,0,0-2.188,0L8.988,45.16h0L8,45.872v.918a.624.624,0,0,0,.624.624H37.315a.624.624,0,0,0,.624-.624v-.918l-.988-.712Z" transform="translate(-8 -19.235)" />
                                    <path className="a" d="M12.079,38.034l6.9-4.97L8,26.587V40.973l4.078-2.939Z" transform="translate(-8 -15.873)" />
                                    <path className="a" d="M23.613,25.034a3.108,3.108,0,0,1,3.643,0l1.067.767,8-4.715V11.905a.618.618,0,0,0-.624-.624H30.661V10.034a.626.626,0,0,0-.624-.624H15.174a.626.626,0,0,0-.624.624V21.086l8,4.715Zm7.163-10.57h2.664a.624.624,0,0,1,0,1.247H30.776a.624.624,0,0,1,0-1.247Zm0,2.654h2.664a.624.624,0,0,1,0,1.247H30.776a.624.624,0,0,1,0-1.247ZM17.428,12.063H27.777a.624.624,0,0,1,0,1.247H17.428a.624.624,0,0,1,0-1.247Zm0,2.654H27.777a.624.624,0,0,1,0,1.247H17.428a.624.624,0,0,1,0-1.247ZM16.8,17.994a.624.624,0,0,1,.624-.624H27.777a.624.624,0,0,1,0,1.247H17.428A.624.624,0,0,1,16.8,17.994Z" transform="translate(-10.465 -9.41)" />
                                </g>
                                </g></svg><span className="svg-icon-text">Contact Us</span>
                        </a>
                    </li>
                </ul>
                <div>
                    <div className="icon-profile-submission">
                        <div className="approval-icon">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SideNav;
