import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {  MDBBtn,MDBInput  } from 'mdbreact';
import { addAssociates, editAssociates, updateAssociates } from '../business-associates/business-associates.actions';
import Input from '../../components/input/input';
import {unitedStates} from '../../config/unitedStates';
import './add-associates.scss';

class AddAssociates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditAssociate: this.props.isEditAssociate,
            toggle:this.props.toggle,
            companyName: '' ,
            firstName: '' ,
            lastName: '',
            email: '',
            address:'',
            city:'',
            selectState:'',
            zipCode:'',
            descriptionServices:'',
            modal8: false,
            toggle: true,
            options: [
                {
                  text: "Option 1",
                  value: "1"
                },
                {
                  text: "Option 2",
                  value: "2"
                },
                {
                  text: "Option 3",
                  value: "3"
                }
              ],
              
        }
        
    }

    componentDidMount(){
      if(this.props.isEditAssociate == true){
        console.log("Edit Mode Activated");
        this.props.editAssociates({
          "action":"get_business_associate_info",
          "user_id":localStorage.getItem('userId'),
          "user_token":localStorage.getItem('token'),
          "business_associate_id":this.props.associateDetails.id
        });
      }
     
    }

    populateAssociateDetails() {

      if (
        this.props.associateData &&
        this.props.associateData !== undefined &&
        this.props.associateData.length > 0
      ) {
        if (this.props.associateData.length > 0) {
          this.setState({
            companyName: this.props.associateData[0].company_name,
            firstName: this.props.associateData[0].firstname,
            lastName: this.props.associateData[0].lastname,
            email: this.props.associateData[0].email,
            address: this.props.associateData[0].address,
            city: this.props.associateData[0].city,
            selectState: this.props.associateData[0].state,
            zipCode: this.props.associateData[0].zipcode,
            descriptionServices: this.props.associateData[0].description
          });
        }
      }
    }

    

    componentDidUpdate(prevProps){
      if (this.props.getAssociateDetails !== prevProps.getAssociateDetails) {
        this.populateAssociateDetails();
      }
    }

    


    handleOnChange = e => {
      this.setState({
        [e.target.name] : e.target.value
      })
    }

    addAssociate = () => {
      this.props.addAssociates({
        "action":"add_business_associate",
        "user_id":localStorage.getItem('userId'),
        "user_token":localStorage.getItem('token'),
        "company_name":this.state.companyName,
        "firstname":this.state.firstName,
        "lastname":this.state.lastName,
        "email":this.state.email,
        "address":this.state.address,
        "city":this.state.city,
        "state":this.state.selectState,
        "zipcode":this.state.zipCode,
        "description":this.state.descriptionServices
      });
    }

    updateAssociate = () => {
      console.log("Update action performed")
      this.props.updateAssociates({
        "user_id":localStorage.getItem('userId'),
        "user_token":localStorage.getItem('token'),
        "action":"save_business_associate",
        "id":this.props.associateDetails.id,
        "company_name":this.state.companyName,
        "firstname":this.state.firstName,		
        "lastname":this.state.lastName,
        "email":this.state.email,			
        "address":this.state.address,
        "city":this.state.city,
        "state":this.state.selectState,			
        "zipcode":this.state.zipCode,		
        "description":this.state.descriptionServices
      });
    }

    saveBtnClick = () => {
      if(this.props.isEditAssociate === true){
        this.updateAssociate();
        
      }
      else{
        this.addAssociate();
      }
    }


    closeBtnClick =  (nr) => {
      console.log("CLose button")
      this.props.closeModal();
  }


    render(){
      console.log("Props.companyname",this.props.associateData);

        return(
            <div className="add-associates">
                <div className="mdl-body">
                 <div><MDBInput type="text" value={this.state.companyName}  label="Company Name" name="companyName" onChange={this.handleOnChange} outline /></div>
               
                    <MDBInput type="text" value={this.state.firstName} label="First Name" name="firstName" onChange={this.handleOnChange} outline />
                    <MDBInput type="text" value={this.state.lastName}  label="Last Name" name="lastName" onChange={this.handleOnChange} outline />
             
                 <div><MDBInput type="email" value={this.state.email}  label="Email" name="email" onChange={this.handleOnChange} outline /></div>
                 <div><MDBInput type="text"  value={this.state.address} label="Address" name="address" onChange={this.handleOnChange} outline /></div>
                 <div><MDBInput type="text" value={this.state.city}  label="City" name="city" onChange={this.handleOnChange} outline /></div>
                 <div>
                    <select className="browser-default custom-select">
                    <option>Select State</option>
                      {unitedStates.states.map(state => {
                      return <option value={state}>{state}</option>
                      })}
                    </select>
                </div> 
                <MDBInput type="text" value={this.state.zipCode}  label="Zip Code" name="zipCode" onChange={this.handleOnChange} outline />
                <MDBInput type="text"  value={this.state.descriptionServices} label="Description of Services" name="descriptionServices" onChange={this.handleOnChange} outline />
                 </div>
                 <div className="mdl-ftr">
                    <MDBBtn type="button" className="cancel-btn" onClick={()=>this.closeBtnClick(8)}>Cancel</MDBBtn>
                    <MDBBtn type="button" className="save-btn" onClick={()=>this.saveBtnClick()}>Save</MDBBtn>

                  </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
  const { associates } = state;
  return associates;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAssociates, editAssociates, updateAssociates }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddAssociates));
