import React, { Component } from 'react';
import LeftNavItems from '../../components/left-nav-items/left-nav-items';
import RightContentArea from '../../components/right-content-area/right-content-area';
import './leftnav-page.scss';

class LeftNavPage extends Component {
    constructor() {
        super();
        this.state = {
            selectedMenu: 1
        }
    }

    selectedMenu = (menu) => {
        this.setState({ selectedMenu: menu })
    }

    render() {
        return (
            <div className="leftnavpagecontainer">
                <div className="conatiner-fluid leftnav">
                    <h1>Training</h1>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-5">
                            <LeftNavItems selectedMenu={this.selectedMenu} />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-7">
                            {this.state.selectedMenu == '1' && <RightContentArea />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeftNavPage;

