import React, { Component } from 'react';

import './dashboard.scss';

export class Dashboard extends Component {
    constructor(){
        super();
    }

    render(){
        return(
            <div className="dashboard">
                <div className="conatiner-fluid">
                        <h1>Dashboard</h1>
                        <div className="row">
                            {/* <div className="col-lg-12 col-md-12 col-sm-12 content-area">
                                    Dashboard Contents
                            </div> */}
                          
                        </div>
                </div>
            </div> 
        );
    }
}
