import { ApiService } from '../../services/api.service';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from './LoginConstants';

export function login(data) {
  return dispatch => {
    dispatch(loginRequested());
    ApiService.callAPIPost(data).then(res => {
      dispatch(loginSuccess(res.data));
    })
    .catch(err => {
      dispatch(loginFailure(err.message));
    });
  };
};

const loginRequested = () => ({
  type: LOGIN_REQUEST
});

const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: {
    ...data
  }
});

const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: {
    error
  }
});
