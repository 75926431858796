import React from 'react';
import { MDBInput } from "mdbreact";
import { PropTypes } from 'prop-types';
import './input.scss';

class Input extends React.Component {

    constructor(props) {
        super(props);

        const inputvalue = props.value || {};
        this.state = {
            value: inputvalue.value
        };
    }

    render() {
        const { id, size, label, name, outline, type, maxLength, placeholder, required, defaultValue, prefix, suffix, onChange, onPressEnter } = this.props;
        const { value } = this.state;
        return (
            <MDBInput
                label={label}
                type={type}
                value={value}
                onChange={onChange}
                outline={outline}
                placeholder={placeholder}
                name={name}
                size={size}
                id={id}
                defaultValue={defaultValue}
                prefix={prefix}
                suffix={suffix}
            />
        );
    }
}

Input.propTypes = {
    id: PropTypes.number,
    size: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    onChange: PropTypes.func
};

export default Input