import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LoginReducer from './LoginReducer';
import { AssociatesReducer } from '../pages/business-associates/business-associates.reducer';
import { HippaAssessmentReducer } from '../pages/hippa-assessment/hippa-assessment.reducer';
//One root reducer for the whole app. This is done so that the app will have one single reducer to manage lots of other resources.
// And also communication between the reducers will be easier to maintain.

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    loginData:LoginReducer,
    associates: AssociatesReducer,
    hippaAssessment: HippaAssessmentReducer
    // app: AppReducer,
    // home: HomeReducer,
    // modal: ModalReducer,
   
});
export default createRootReducer;
