import { 
  GET_HIPPA_ASSESSMENT_REQUEST, 
  GET_HIPPA_ASSESSMENT_REQUEST_SUCCESS, 
  GET_HIPPA_ASSESSMENT_REQUEST_FAILURE, 
  SAVE_ANSWER_REQUEST,
  SAVE_ANSWER_REQUEST_SUCCESS,
  SAVE_ANSWER_REQUEST_FAILURE
} from './hippa-assessment.constants';

const initialState = {
  loading: false,
  questionAnswer: [],
  random:'',
  error: null,
  answer:''
}

export function HippaAssessmentReducer(state = initialState, action) {

  switch (action.type) {
      case GET_HIPPA_ASSESSMENT_REQUEST:
          state.loading = true;
          return Object.assign({}, state, state.loading);

      case GET_HIPPA_ASSESSMENT_REQUEST_SUCCESS:
              let response = [];
              let questions = action.payload.question_headings;
              let answer = action.payload.answer;
              let notesRegex = new RegExp('\\bnotes\\b');
              let questionRegex = new RegExp('\\bquestion.*\\b');
              let headingRegex = new RegExp('\\bheading.*\\b');
              let alreadyGotNextQuestion = false;
              // let lastAnsweredId = -1;
              
      
              for (const property in questions) {
                if (notesRegex.test(property)) {
                    let obj = {
                        type: 'notes',
                        title: questions[property],
                        id: property.substring(property.length -1, property.length)
                      };
                      response.push(obj);
                    }
                if (questionRegex.test(property)) {
                  const idString = property.substring('question'.length, property.length);
                  const answerFetched = answer[`que${idString}`] ? answer[`que${idString}`] : '';
                  let faded = true;
                  if (!answerFetched) {
                    // lastAnsweredId = parseInt(idString);
                    if (!alreadyGotNextQuestion) {
                      faded = false;
                      alreadyGotNextQuestion = true;
                    }
                  }
                  // questionCounter++;
                    let obj = {
                        type: 'question',
                        title: questions[property],
                        id: idString,
                        value: false,
                        answer: answerFetched,
                        faded
                     };
                    response.push(obj);
                  }
                if (headingRegex.test(property)) {
                    let str = 'heading';
                    let obj = {
                        type: 'heading',
                        title: questions[property],
                        id: property.substring(str.length, property.length)
                    };
                    response.push(obj);
                  }
                }
          state.loading = false;
          state.random = action.payload.random;
          state.questionAnswer = response;
          return Object.assign({}, state, state.questionAnswer);

      case GET_HIPPA_ASSESSMENT_REQUEST_FAILURE:
          state.loading = false;
          state.error = action.payload.error;
          return Object.assign({}, state, state.error);

      case SAVE_ANSWER_REQUEST:
           state.loading = true;
           return Object.assign({}, state, state.loading)

      case SAVE_ANSWER_REQUEST_SUCCESS:
          state.loading = false;
          state.answer = action.payload;
          let questionAnswerArrayModified = state.questionAnswer;
          const modifiedId = action.payload.id;
          let changeNextIndexToFaded = false;
          for (let index = 0; index < questionAnswerArrayModified.length; index++) {
            const questionAnswerDetails = questionAnswerArrayModified[index];
            if (questionAnswerDetails.type === 'question') {
              if (questionAnswerDetails.id === modifiedId) {
                questionAnswerDetails.faded = true;
                questionAnswerDetails.answer = action.payload.answer;
                questionAnswerArrayModified[index] = questionAnswerDetails;
                changeNextIndexToFaded = true;
              } else if (changeNextIndexToFaded === true) {
                questionAnswerDetails.faded = false;
                questionAnswerArrayModified[index] = questionAnswerDetails;
                changeNextIndexToFaded = false;
                break;
              }
            }
          }
          state.questionAnswer = questionAnswerArrayModified;
          return Object.assign({}, state, state.answer);

      case SAVE_ANSWER_REQUEST_FAILURE:
           state.loading = false;
           state.error = action.payload.error;
           return Object.assign({}, state, state.error);

      default:
              return state;
  }
}