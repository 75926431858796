import {
    GET_BUSINESS_ASSOCIATES_REQUEST,
    GET_BUSINESS_ASSOCIATES_REQUEST_SUCCESS,
    GET_BUSINESS_ASSOCIATES_REQUEST_FAILURE,
    VIEW_BA_PDF_REQUEST,
    VIEW_BA_PDF_REQUEST_SUCCESS,
    VIEW_BA_PDF_REQUEST_REQUEST_FAILURE,
    ADD_ASSOCIATE_REQUEST,
    ADD_ASSOCIATE_REQUEST_SUCCESS,
    ADD_ASSOCIATE_REQUEST_FAILURE,
    EDIT_ASSOCIATE_REQUEST,
    EDIT_ASSOCIATE_REQUEST_SUCCESS,
    EDIT_ASSOCIATE_REQUEST_FAILURE,
    UPDATE_ASSOCIATE_REQUEST,
    UPDATE_ASSOCIATE_REQUEST_SUCCESS,
    UPDATE_ASSOCIATE_REQUEST_FAILURE
} from './business-associates.constants';

const initialState = {
    loading: false,
    data: [],
    associateData: [],
    error: null,
    getAssociateDetails: 0,
    pdfData: [],
    updatedData:''
}
export function AssociatesReducer(state = initialState, action) {

    switch (action.type) {
        case GET_BUSINESS_ASSOCIATES_REQUEST:
            state.loading = true;
            return Object.assign({}, state, state.data)

        case GET_BUSINESS_ASSOCIATES_REQUEST_SUCCESS:
            state.loading = false;
            state.data = action.payload.business_associate;
            return Object.assign({}, state, state.data);

        case GET_BUSINESS_ASSOCIATES_REQUEST_FAILURE:
            state.loading = false;
            state.error = action.payload.error;
            return Object.assign({}, state, state.error);

        case VIEW_BA_PDF_REQUEST:
            state.loading = true;
            return Object.assign({}, state, state.loading)

        case VIEW_BA_PDF_REQUEST_SUCCESS:
            state.loading = false;
            state.pdfData = action.payload;
            return Object.assign({}, state, state.pdfData)

        case VIEW_BA_PDF_REQUEST_REQUEST_FAILURE:
            state.loading = false;
            state.error = action.payload.error;
            return Object.assign({}, state, state.error);

        case ADD_ASSOCIATE_REQUEST:
            state.loading = true;
            return Object.assign({}, state, state.associateData)

        case ADD_ASSOCIATE_REQUEST_SUCCESS:
            state.loading = false;
            state.associateData = action.payload;
            return Object.assign({}, state, state.associateData);

        case ADD_ASSOCIATE_REQUEST_FAILURE:
            state.loading = false;
            state.error = action.payload.error;
            return Object.assign({}, state, state.error);

        case EDIT_ASSOCIATE_REQUEST:
            state.loading = true;
            state.getAssociateDetails = 0;
            return Object.assign({}, state, state.associateData)

        case EDIT_ASSOCIATE_REQUEST_SUCCESS:
            state.loading = false;
            state.getAssociateDetails = 1;
            state.associateData = action.payload.business_associate;
            return Object.assign({}, state, state.associateData);

        case EDIT_ASSOCIATE_REQUEST_FAILURE:
            state.loading = false;
            state.getAssociateDetails = 0;
            state.error = action.payload.error;
            return Object.assign({}, state, state.error);

        case UPDATE_ASSOCIATE_REQUEST:
             state.loading = true;
             return Object.assign({}, state, state.updatedData);

        case UPDATE_ASSOCIATE_REQUEST_SUCCESS:
             const index = state.data.findIndex(data => data.id == action.payload.id);
             let myData = state
             myData.data[index].company_name = action.payload.company_name
             state.loading = false;
             state.updatedData = action.payload;
             return Object.assign({}, state, myData);

        case UPDATE_ASSOCIATE_REQUEST_FAILURE:
             state.loading = false;
             state.error = action.payload.error;
             return Object.assign({}, state, state.error);


        default:
            return state;
    }
}

